@import "../variables";
@import "breakpoint-sass/stylesheets/breakpoint";

html {
	font-family: $fontFamilyDefault;
	font-size: $fontSizeRootSteps0-0;
	line-height: $lineHeightBase;
	color: $primaryColor;
	overflow-y: scroll;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--ms3-scroll-padding-top: 0px;
	scroll-behavior: smooth;
	scroll-padding-top: calc(#{$fhbBannerHeight + 50px} + var(--ms3-scroll-padding-top));
	--main-navigation-height: 0px;
	--footer-height: 0px;
}

@include breakpoint($steps1up) {
	html {
		font-size: $fontSizeRoot;
		scroll-padding-top: calc(20px + var(--main-navigation-height) + var(--ms3-scroll-padding-top));
	}
}

html, body {
	margin: 0;
	padding: 0;
}

/*
 * A better looking default horizontal rule
 */
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}


/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

abbr,
acronym {
	border-bottom: .1em double;
	cursor: help;
	text-decoration: none;
}
