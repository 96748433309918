@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

// fix debug messages breaking the page layout
.extbase-debugger {
	position: absolute;
}

.frame-space-before-medium {
	padding-top: 4rem;
}

.frame-space-after-medium {
	padding-bottom: 4rem;
}

.frame-space-after-small {
	padding-bottom: 1.5rem;
}

.frame-space-before-small {
	padding-top: 1.5rem;
}
