@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

$stripe1: 4px; // orange
$stripe2: 2px; // white
$stripe3: 10px; // green

.vmzhb-page {
	background: white;
	--footer-height: 0px;
	--notifications-height: 0px;

	a:hover,
	a:focus {
		color: $themeColorB;
	}

	.vmzhb-site--content-hidden & {
		display: none;
	}

	@include breakpoint($stepsMobileOnly) {
		margin-top: $fhbBannerHeight + 20px;
		min-height: calc(100vh - #{$fhbBannerHeight + 40px} - var(--footer-height));
	}

	@include breakpoint($steps1up) {
		box-shadow: 3px 3px 1px rgba(50, 50, 50, 0.45);
		background: rgba(255, 255, 255, 0.98);
		border: 1px solid $secondaryColor;
		margin-top: calc(#{$fhbBannerHeight + 20px} + var(--main-navigation-height));
		min-height: calc(100vh - #{$fhbBannerHeight + 40px} - var(--main-navigation-height) - var(--footer-height) - var(--notifications-height));
	}
}
