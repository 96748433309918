@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-scroll-to-top-button {
	position: fixed;
	right: 3vw;
	bottom: 3vw;
	width: 5vw;
	min-width: 40px;
	max-width: 70px;
	height: 5vw;
	min-height: 40px;
	max-height: 70px;
	background: {
		image: url("../img/scroll-to-top.svg");
		repeat: no-repeat;
		position: center;
		color: #333;
		size: 50%;
	}
	display: none;
	border-radius: 50%;
	box-shadow: 0px 0px 10px .2rem #fff;

	@include breakpoint($stepsMobileOnly) {
		bottom: 70px;
	}

	.vmzhb-site:not(.vmzhb-site--content-hidden).vmzhb-site--scrolled & {
		display: block;
	}

	&:hover,
	&:focus {
		background-color: $mainNavColorBg;
	}
}
