@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-chart {
	g.highcharts-legend-item rect.highcharts-point {
		stroke: rgb(62, 87, 111);
	}

	g.highcharts-legend-item-hidden rect.highcharts-point {
		fill: white;
	}

	&-wrapper {
		margin: 5px -4px;
		border: {
			style: solid;
			color: #ccc;
			width: 1px;
			radius: 4px;
		}

		background: white;
	}

	&-buttons {
		display: flex;
		padding: 6px;
		margin-bottom: 5px;
		justify-content: space-between;
		border-bottom: 1px solid #ccc;

		background: #f5f5f5;
	}

	&-button {
		&--download {
			display: block;
			padding: 5px 5px 0 5px;
			vertical-align: text-bottom;
		}

		&--submit,
		&--reset {
			height: 27px;
			width: 27px;
			margin: 0 0 0 5px;
			padding: 2px 5px;
			border: 0;
			border-radius: 3px;
			background: #f5f5f5;
		}

		&--reset  {
			color: #444;
		}

		&--submit  {
			border: 1px solid #ccc;
		}
	}

	&-form {
		display: flex;
		align-items: flex-start;
	}

	&-date-selector {
		width: 170px;
		padding: 2px 5px;
		border: 1px solid #ccc;
		border-radius: 3px;
	}
}
