.vmzhb-rz-wizard-button {
	display: inline-block;
	margin: 5px 10px 5px 0;
	padding: 4px 7px 2px 7px;
	border: 1px solid #ccc;
	border-radius: 3px;

	background: #fff;
	color: #000;
	text-align: center;

	&:last-child {
		margin-right: 0;
	}

	&--primary {
		background: #000;
		color: #fff;
		border-color: #000;
		font-weight: bold;
	}
}
