@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-vba-a1-a27 {
	position: relative;

	display: block;
	margin: 1em 0;
	border: 1px solid #e5e5e5;
	background: #e5e5e5;

	line-height: 1.5;

	&__map,
	&__diagramm {
		display: inline-block;
		width: 100%;
	}

	&__tooltip {
		position: absolute;
		left: 50%;
		bottom: 0;

		display: none;
		width: 300px;
		margin-left: -150px;
		margin-bottom: 45px;
	}
}


.ce-table__vba {
	min-width: 200px !important;

	tr {
		cursor: pointer
	}

	th {
		padding: 5px;
	}

	td {
		padding: 10px;

		&:nth-child(1) {
			white-space: nowrap;
		}

		&:nth-child(2) {
			text-align: right;
		}
	}

	tr:not(.ce-table__vba-embedded):hover td {
		background-color: lightgray !important;
	}
}

.ce-table__vba-embedded {
	border-width: 0;

	table {
		width: 100%;
	}

	td {
		padding: 0;
	}

	tr {
		cursor: default;

		&:first-child,
		&:nth-child(3) {
			display: none;
		}

		&:nth-child(2) {
			font-weight: bold;
			text-align: center !important;
		}
		&:last-child td {
			border-bottom: 3px solid #ccc;
		}
	}

	table td {
		padding: 2px 5px
	}
}
