@import "breakpoint-sass/stylesheets/breakpoint";
@import "@neonaut/lib-scss/mixins/clearfix";

@import "../variables";

.vmzhb-container {
	clear: both;
	margin: 0 auto;
	width: 100%;
	max-width: $pageMaxWidth + 40px;
	padding: 0;
	transition: padding 200ms linear;
	position: relative;

	@include breakpoint($steps1up) {
		padding: 0 20px;
	}

	@if $debug { outline: 1px dashed grey; }
}

.vmzhb-container__inner {
	@include clearfix;
	max-width: $pageMaxWidth;

	@if $debug { outline: 1px dashed black; }
}
