@import "../mixins/content-container";

.vmzhb-content-container {
	@include vmzhb-content-container;

	transition: opacity ease-in-out 300ms;

	.vmzhb--page-loading & {
		opacity: 0.7;
	}
}
