@import "breakpoint-sass/stylesheets/breakpoint";
@import "@neonaut/lib-scss/mixins/clearfix";

@import "../variables";

/*****************************************
 * Default CSS from fluid_styled_content *
 *****************************************/

/*.ce-left .ce-gallery,*/
.ce-column {
	float: left;
}

.ce-center .ce-outer {
	position: relative;
	float: right;
	right: 50%;
}

.ce-center .ce-inner {
	position: relative;
	float: right;
	right: -50%;
}

/*
.ce-right .ce-gallery {
	float: right;
}
*/

.ce-gallery figure {
	display: table;
	margin: 0;
}

.ce-gallery figcaption {
	display: table-caption;
	caption-side: bottom;
}

.ce-gallery img {
	display: block;
}

.ce-gallery iframe {
	border-width: 0;
}

.ce-border img,
.ce-border iframe {
	border: $stylesContentTextmediaBorderWidth solid $stylesContentTextmediaBorderColor;
	padding: $stylesContentTextmediaBorderPadding;
}

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
	margin-bottom: $stylesContentTextmediaTextMargin;
}

.ce-intext.ce-right .ce-gallery {
	margin-left: $stylesContentTextmediaTextMargin;
}

.ce-intext.ce-left .ce-gallery {
	margin-right: $stylesContentTextmediaTextMargin;
}

.ce-below .ce-gallery {
	margin-top: $stylesContentTextmediaTextMargin;
}

.ce-column {
	margin-right: $stylesContentTextmediaColumnSpacing;
}

.ce-column:last-child {
	margin-right: 0;
}

.ce-row {
	margin-bottom: $stylesContentTextmediaRowSpacing;
}

.ce-row:last-child {
	margin-bottom: 0;
}

.ce-above .ce-bodytext {
	clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
	padding-left: 40px;
	overflow: auto;
}

/************************
 * Textpic              *
 ************************/

.ce-textpic.ce-right {
	@include clearfix;
}

/************************
 * Uploads              *
 ************************/
.ce-uploads {
	padding: 0;
	margin: 1.2em 0;
	overflow-x: scroll;

	a {
		text-decoration: none;
		font-weight: bold;
		text-align: left;
		color: $themeColorA;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: $themeColorB;
		}
	}

	div {
		overflow: hidden;
	}

	li {
		list-style: none outside none;
		margin: 0;
		padding: 0.2em 0;
		white-space: nowrap;
		display: table-row;
	}

	img {
		float: left;
		vertical-align: top;
	}
}

.ce-uploads {
	padding: 0;
	margin: 1.2em 0;

	a {
		text-decoration: none;
		font-weight: bold;
		text-align: left;
		color: $themeColorA;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: $themeColorB;
		}
	}

	div {
		overflow: hidden;
	}

	li {
		list-style: none outside none;
		margin: 0;
		overflow: hidden;
	}

	img {
		float: left;
		vertical-align: top;
	}
}

/************************
 * Tables               *
 ************************/
.ce-table {
	@extend .vmzhb-overflow-scroll-area;
	width: 100%;
	min-width: 500px;
	table-layout: auto;
	overflow-x: scroll;
	margin: 1.8em 0;

	caption {
		margin: 1em 0;
	}

	tfoot {
		color: $secondaryColor;
		font-size: $fontSizeMeta;
	}

	tbody tr {
		background: $pageBackgroundColor;

		&:nth-child(even) {
			background: $backgroundHighlightColor;
		}

		& + tr {
			border-top: 1px solid #ccc;
		}
	}

	td {
		border: 1px dotted #ccc;
		border-top: none;
	}

	th,
	td {
		vertical-align: top;
		text-align: left;
		padding: 10px 5px;

		p {
			margin: 0;

			& + p {
				margin-top: 15px;
			}
		}
	}

	th {
		background-color: $themeColorA;
		color: $invertedPrimaryColor;
		font-weight: bold;

		b,
		strong {
			font-weight: normal;
		}
	}
}

.layout-1 {
	.ce-table {
		th {
			background-color: $tableColorA;
		}
	}
}

.layout-2 {
	.ce-table {
		th {
			background-color: $tableColorB;
		}
	}
}

.layout-3 {
	.ce-table {
		th {
			background-color: $tableColorC;
		}
	}
}

/************************
 * felogin              *
 ************************/

.tx-felogin-pi1 {
	legend {
		display: none;
	}

	label {
		display: block;
		font-size: $fontSizeMeta;
		line-height: 1.8em;
	}

	fieldset > div {
		padding: 0.3em;
	}
}

/************************
 * Bodytext             *
 ************************/

.ce-bodytext {
	ul {
		li {
			list-style-type: square;
		}
	}
}

/************************
 * Media elements       *
 * - Pictures           *
 * - Gallery            *
 ************************/
.ce-gallery {
	margin-top: 0.5em;
	overflow-x: auto;

	.typo3-fancybox {
		display: block;
		margin: 2px;

		&:focus {
			outline: 2px solid $themeColorB;
		}
	}

	figure {
		width: 100%;
		display: table;
		margin: 0;
	}

	figcaption {
		display: table-caption;
		caption-side: bottom;
		font-size: $fontSizeSmallPrint;
	}

	img {
		width: 100%;
		height: auto;
	}

	iframe {
		border-width: 0;
	}

	.ce-column {
		float: none;
		width: auto;
		display: table-cell;
		vertical-align: top;
		font-size: $fontSizeBase;
		overflow: hidden;
		white-space: normal;
		padding-right: 10px;

		&:last-child {
			padding-right: 0;
		}
	}

	&--scroll {
		figure {
			padding-bottom: 10px;
		}

		figcaption {
			display: none;
		}

		img {
			width: auto;
		}
	}
}

@include breakpoint($stepsMobileOnly) {
	.ce-gallery[data-ce-columns='1'] {
		//max-width: 45%;
	}
}

.ce-intext.ce-right .ce-gallery {
	float: right;
}

.ce-intext.ce-left .ce-gallery {
	float: left;
}

// Bildgalery Spalten
.ce-gallery[data-ce-columns='2'] .ce-column {
	//width: 50%;
}

.ce-gallery[data-ce-columns='3'] .ce-column {
	// width: 33%;
}

.ce-gallery[data-ce-columns='4'] .ce-column {
	width: 25%;
}
