@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-feature {
	&__image {
		height: auto;
		width: auto;
		max-height: 180px;
		padding: 0;
		border: 1px solid $tableLineColor;
		box-shadow: none;
	}

	&__image-caption,
	&__coordinates {
		font-size: $fontSizeSmallPrint;
		color: $secondaryColor;
	}

	&__image-caption{
		margin: 0.4em 0 0.6em 0;
	}

	.vmzhb-rz-station__chart {
		margin: 1em 0 0.6em 0;
	}

	.vmzhb-webcams {
		width: 100%;
	}
}
