@use "sass:math";

@import "../variables";

$hamburgerWidthHalf: floor(math.div($hamburgerWidth, 2));

.vmzhb-main-navigation-toggle {
	position: relative;
	z-index: 3;
	display: block;
	height: $fhbBannerInnerHeight;
	width: 50px;
	font-weight: normal;
	text-decoration: none;
	background-color: transparent;
	border: 0;

	i,
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		height: $hamburgerHeight;
		width: $hamburgerWidth;
		background-color: $mainNavColorBg;
		margin: 0 0 0 (-$hamburgerWidthHalf);
		opacity: 1;

		transition-property: transform;
		transition-duration: 250ms;
		transition-timing-function: ease;
		transition-delay: 0ms;
	}

	i {
		margin-top: -1 * floor(math.div($hamburgerHeight, 2));
		transition-property: opacity;
		transition-duration: 250ms;
		transition-timing-function: linear;
		transition-delay: 50ms;
	}

	&::before {
		margin-top: -1 * floor($hamburgerSpacing + $hamburgerHeight + math.div($hamburgerHeight, 2));
	}

	&::after {
		margin-top: round($hamburgerSpacing + math.div($hamburgerHeight, 2));
	}

	&[aria-expanded="true"] {
		i {
			opacity: 0;
			transition-duration: 50ms;
			transition-delay: 0ms;
		}

		&::before {
			transform: translateY(floor($hamburgerSpacing + $hamburgerHeight + math.div($hamburgerHeight, 2))) rotate(45deg);
		}

		&::after {
			transform: translateY(-1 * round($hamburgerSpacing + math.div($hamburgerHeight, 2))) rotate(-45deg);
		}
	}

	.no-js & {
		display: none;
	}

	@media print {
		display: none !important;
	}
}
