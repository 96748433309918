@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-content {
	a {
		word-break: break-all;
		color: $linkColor;
	}

	th,
	td {
		padding: 5px 10px 5px 0;
	}

	th {
		border-bottom: 1px solid #666;
		text-align: left;
		font-weight: bold;
	}

	td {
		border-top: 1px solid #ccc;
	}

	h2 {
		margin-top: 1.5625rem;
		border-top: 1px solid $linkColor;
	}
}
