@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-fhb-banner {
	position: relative;
	float: left;
	height: $fhbBannerHeight;
	width: 100.75%;
	margin-left: -7px;
	white-space: nowrap;

	.vmzhb-site--scrolled & {
		@include breakpoint($steps1up) {
			display: none;
		}
	}

	&__item,
	&__spacer {
		display: inline-block;
		vertical-align: top;
		margin-right: -4px;
		overflow: hidden;
	}

	&__item {
		padding: $fhbBannerPadding;
		line-height: $fhbBannerInnerHeight;

		a {
			display: inline-block;

			&:focus,
			&:active {
				outline: 2px solid $themeColorB;
			}
		}

		&--right {
			@include breakpoint($steps1up) {
				position: absolute;
				right: -42px;
			}
		}

		&--desktop-only {
			display: none;

			@include breakpoint($steps1up) {
				display: inline-block;
				margin-left: 7px;
			}

			&:focus,
			&:active {
				border: none;
			}
		}

		&--mobile-only {
			display: none;

			@include breakpoint($stepsMobileOnly) {
				display: inline-block;
				margin-left: 7px;
			}

			&:focus,
			&:active {
				border: none;
			}
		}
	}

	&__spacer {
		display: none;
		// background-color: $bannerColorBgLight;
		height: 37px;
		width: 1px;
		margin: 3px 0 0 11px;
	}

	a, span {
		color: $bannerColorText;
		text-decoration: none;
	}
}
