@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-footer {
	float: left;
	width: 100%;
	clear: both;
	color: $secondaryColor;
	padding: 0;
	margin-top: 20px;
	box-shadow: 0 0 1.25rem #00000080;
	background-color: #fff;

	@media print {
		box-shadow: none;
	}

	.ms3--map-no-scroll &,
	.vmzhb-site--content-hidden & {
		display: none;
	}

	&__small-print {
		display: block;
		text-align: center;
		font-size: inherit;
		max-width: $pageMaxWidth;
		margin: {
			left: auto;
			right: auto;
			top: 10px;
		}
		padding: {
			top: 10px;
			bottom: 10px;
		}

		img {
			margin-left: 7px;
		}

		@include breakpoint($steps1up) {
			text-align: right;
		}
	}

	@if $debug {
		outline: 1px dashed blue;
	}
}
