@use "sass:math";
@import "variables";
@import "mixins/content-container";
@import "breakpoint-sass/stylesheets/breakpoint";
@import "@neonaut/lib-scss/mixins/visually-hidden";

$ms3-iconPath: '/assets/img/';
$ms3-primaryColor: $primaryColor;
$ms3-secondaryColor: $secondaryColor;
$ms3-tertiaryColor: $tertiaryColor;
$ms3-tableLineColor: $tableLineColor;
$ms3-pageBackgroundColor: $pageBackgroundColor;
$ms3-tableLineColor: $tableLineColor;

$ms3-themeColorA: $themeColorA;
$ms3-themeColorB: $themeColorB;

$ms3-step1: $step1;

$ms3-rem-px-ratio: math.div($fontSizeRoot, 1px);
$ms3-fullscreen-top: $fhbBannerHeight;
$ms3-verticalPanelPadding: 20px;
$ms3-borderRadius: 0;

$ms3-option-mapOverlayButtonsShowLabel: false;

// those are used only by ms3 which uses Bremen's values as defaults
//$ms3-backgroundColor: $backGroundColor;
//$ms3-borderColor: $borderColor;
//$ms3-borderRadius: $borderRadius;

// TODO: more definitions if missing

@import "@mapsight/ui/src/scss/default";

// @import "blocks/mapsight-ui/default";
@import "blocks/content-container";

.ms3--map-no-scroll .vmzhb-area--meta {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}

.ms3-modal .ms3-logo {
	margin: 10px;
	border: 5px solid #222;
	width: 130px;
	height: 50px;
	background-color: #222;
	border-radius: 10px;
	box-shadow: 3px 3px 5px rgba(0 0 0 / 30%);
}

.ms3-marginal {
	h2 {
		color: $ms3-tertiaryColor;
		font-weight: 700;
		border: 0;
		padding: 0;
		margin: 0;
		letter-spacing: 0;
	}

	.ms3-wrapper--mapOnly &,
	.ms3-wrapper--fullscreen & {
		display: none;
	}

	@media (min-width: 900px) {
		margin-left: 75%;
		width: 25%;
	}
}

.ms3-wrapper.ms3-wrapper--fullscreen,
.ms3-wrapper.ms3-wrapper--mapOnly {
	top: $fhbBannerHeight;
}

.ms3-additional-container {
	display: contents;

	&__content {
		@include vmzhb-content-container;
	}
}

.ms3-feature-selection-info__wrapper {
	overflow: auto;

	.js .ms3-wrapper--desktop &,
	.js .ms3-wrapper--fullscreen & {
		width: 309px; // we are a bit smaller as we show a 10px rounded gray background around the details panel content
	}
}

.ms3-feature-selection-info {
	$b: &;

	&__header {
		padding: 15px 50px 12px 15px;
	}

	h1,
	h2,
	h3,
	h4 {
		padding: 0;
		margin-top: 0;
	}

	table {
		margin-bottom: 0.5em;
	}

	th, td {
		padding-right: 5px;
		text-align: left;
	}

	// .ms3-wrapper--mapOnly &,  // hier ist das overflow:hidden nötig, glz. kümmern wir uns auf mobil nicht um die Tastatursteuerung
	.ms3-wrapper--desktop &,
	.ms3-wrapper--fullscreen & {
		height: calc(100% - 20px);
		margin: 10px;
		overflow: visible;

		#{$b}__content {
			padding: 0 15px 20px;
		}
	}

	&__content {
		.ms3-wrapper--desktop &,
		.ms3-wrapper--fullscreen &,
		.ms3-wrapper--mapOnly & {
			padding: 0 15px 20px 15px;
		}
	}
}

.ms3-features-sorting {
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -20px;
	text-align: right;

	.ms3-wrapper--mobile & {
		// FIXME: remove default margin from mapsight/ui so we dont have to override it here
		margin-top: -20px;
	}
}

.ms3-feature-details-content {
	ul {
		padding-left: 1.2em;
	}
}

.ms3-map-target {
	clear: both;
	height: calc(100vh - #{$ms3-fullscreen-top});
	max-height: 33vh;
	width: 100%;
	flex-grow: 1;
	background: #efece5;
	transition: max-height 200ms ease-out;

	.ms3-wrapper--desktop & {
		min-height: 500px;
	}

	.ms3-wrapper--mapOnly &,
	.ms3-wrapper--fullscreen & {
		height: auto;
		max-height: calc(100vh - #{$ms3-fullscreen-top});
	}

	.no-js & {
		display: none;
	}
}

.ms3-tooltip {
	z-index: 300;
}

.ms3-list {
	$b: &;

	&__item {
		display: block;
		border-top: 1px solid $ms3-borderColor;

		&__head {
			display: contents;
		}

		&--selected {
			#{$b}__main,
			#{$b}__info {
				color: $tertiaryColor;
			}

			&:hover {
				#{$b}__main,
				#{$b}__info {
					color: $themeColorB;
				}
			}
		}
	}

	&__details {
		border-top: 1px dashed #ccc;
		color: #222;
		display: block;
		margin: 7px 0 5px;
		max-width: 100%;
		overflow: auto;
		padding: 5px;
	}

	.ms3-wrapper--fullscreen &,
	.ms3-wrapper--mobile & {
		&__item {
			display: block;
			padding: 6px;
		}

		&__main,
		&__info {
			margin-left: 35px;
		}

		&__icon {
			float: left;

			img {
				margin: -4px -3px;
			}
		}

		&__item--selected #{$b}__main {
			font-size: $ms3-fontSizeHeadline;
			font-weight: 700;
			line-height: 1.3;
		}

		&__info {
			width: auto; // FIXME: Remove default 100% in mapsight/ui so we dont have to override it here
			color: $ms3-secondaryColor;

			br {
				content: "";

				&:after {
					content: ", ";
				}
			}
		}
	}

	.ms3-wrapper--desktop & {
		display: table;

		&__item {
			display: table-row;

			&:nth-child(even) {
				background-color: #f5f5f5;
			}
		}

		&__main,
		&__info,
		&__icon {
			display: table-cell;
			vertical-align: top;
			padding: 4px;
			border-top: 1px solid $ms3-borderColor;
		}

		&__main {
			width: 80%;

			// last child means there is no info, so we can make the main column wider
			&:last-child {
				width: 100%;
			}
		}

		&__info {
			width: 20%;
			min-width: 100px;
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__button {
		&--selected {
		}
	}

	&__filter-box {
		max-width: 360px;
		width: calc(100% - 40px);
		padding: 10px 0;

		.ms3-wrapper--fullscreen & {
			padding: 10px;
		}

		.ms3-input-container {
			width: 100%;
			border-radius: 4px;
			border: 1px solid #444;

			&__element {
				box-sizing: border-box;
				width: 100%;
				padding: 6px 50px 5px 8px;
				border: 0;
				border-radius: 4px;
			}
		}
	}
}

.ms3-map-overlay__button--zoom .ms3-map-overlay__button__label {
	@include visually-hidden;
}

.ms3-layer-switcher-overlay .ms3-layer-switcher-container {
	margin-top: 5px;
}

.ms3-filter-button {
	padding: 8px 14px;
}

.vmzhb--main-navigation-open .ms3-wrapper--mapOnly {
	display: none;
}

.ms3-modal {
	&,
	&__inner {
		width: 100%;
	}
}

.ms3-map-row {
	.ms3-wrapper--desktop &,
	.ms3-wrapper--mobile & {
		border-bottom: 1px solid #000;
	}
}

.ms3-panel--docked-below,
.ms3-panel__inner {
	overflow: hidden !important;
}

@media print {
	.ms3-map-target .ms3-map-overlay {
		display: none !important;
	}

	.ms3--print-feature-selection-info-only {
		.ms3-panel--docked-left {
			position: initial !important;
		}

		.ms3-feature-selection-info__wrapper {
			width: initial !important;
		}

		.ms3-panel-container--vertical {
			width: initial !important;
		}
	}
}

/* Hide POI print button an touch devices */
@media only screen and (hover: none) and (pointer: coarse) {
	.js-ms3-feature-link--print {
		display: none;
	}
}

/* Hide POI print button an small screen devices */
@media only screen and (max-width: 899px) {
	.js-ms3-feature-link--print {
		display: none;
	}
}

// FIX calc with an unclear "100%" in it
.ms3-attribution {
	max-width: 60vw;
	padding: 4px;

	.ms3-modal & {
		padding: 10px;
	}
}

// we have search as you type → make the search icon a field label
.ms3-search__send-button {
	display: none;
}

.ms3-search__input-group {
	border: none;
	width: calc(100% - 10px);
}

.ms3-search__input {
	padding-left: 37px;
	width: 147px;
	background-image: url(/assets/img/mapsight-ui/search.svg);
	background-position: 10px 50%;
	background-repeat: no-repeat;
	border-radius: 0;
}

.ms3-search__reset-button {
	width: 35px;

	&:before {
		margin-top: 2px;
		margin-left: 3px;
	}
}

.ms3-query-input-with-label {
	&__label {
		text-transform: uppercase;
		color: $secondaryColor;
	}

	&__input-container {
		width: 100%;
	}

	&__input {
		box-sizing: border-box !important;
		appearance: none !important;
		border: 1px solid #7e7e7e;
		border-radius: 4px;
	}
}

.ms3-page-title {
	margin: 10px;

	.js .ms3-wrapper--desktop &,
	.js .ms3-wrapper--fullscreen & {
		@include visually-hidden;
		display: block;
	}

	.js .ms3-wrapper--withoutmap &,
	.js .ms3-wrapper--desktop .ms3-wrapper--withoutmap &,
	.js .ms3-wrapper--fullscreen .ms3-wrapper--withoutmap & {
		display: none;
	}
}

.vmzhb-legend {
	display: flex;
	flex-direction: column;
	padding: 0 10px 20px 10px;
}

.vmzhb-layer-legend {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	gap: 0.5em;
	font-size: $fontSizeMeta;

	&--mini {
		justify-content: space-evenly;
	}

	&__icon {
		pointer-events: none;
		user-select: none;
	}

	&__label {
		white-space: nowrap;
	}
}

svg.vmzhb-layer-legend__icon {
	height: 1em;
	width: 1em;
	margin-top: -2px;
	margin-right: 3px;
}

img.vmzhb-layer-legend__icon {
	height: 30px;
	width: 30px;
	margin: -3px -2px -3px -3px;
}

.vmzhb-map-overlay-bottom {
	position: absolute;
	left: 70px;
	right: 70px;
	bottom: 11px;
	text-align: center;
	z-index: 1;

	.ms3-wrapper--fullscreen & {
		left: 110px; // see _mapsight-ui-integration .ms-logo width
		right: 70px;
		bottom: 15px;
	}

	// workaround to not override "display: none" for mobile
	.ms3-wrapper--fullscreen .ms3-info-overlay__area {
		display: inline-block;
	}

	.vmzhb-mini-legend {
		width: 100%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;

		@include breakpoint($ms3-breakpoint-mobile) {
			display: none;
		}

		.ms3-mini-legend {
			border-radius: 5px;
			border: 0;
			box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);

			&__legend,
			&__select,
			&__more {
				padding: 2px;
			}

			.ms3-wrapper--mobile & {
				display: none;
			}

			select {
				max-width: 120px;
				text-overflow: ellipsis;
				line-height: 1;
				accent-color: white;
				font-size: $fontSizeMinor;
				padding: 0 2px;
				border: {
					style: solid;
					width: 0;
					radius: 4px;
				}
			}

			&__legend {
				padding: 0 2px;
			}

			&__more {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
