@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-webcams {
	padding: 5px;
	margin: 2% 0;

	.vmzhb-layout-wide & {
		@include breakpoint($steps1up) {
			margin: 2% -8.2%;
		}
	}

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin: 0 -4px 0 0;
		padding: 6px;
		width: 25%;
		min-height: 220px;

		figcaption {
			display: block;
		}

		@include breakpoint($stepsMobileOnly) {
			width: 50%;
			min-height: 130px;
		}
	}

	&__image {
		width: 100%;
		box-shadow: 3px 3px 4px -2px #000;
		border: 1px solid #333;
		margin-bottom: 7px;
	}

	&__preview {
		position: absolute;
		right: 0;
		top: 0;
		color: black;
		background: gray;
		padding: 2px;
		font-size: $fontSizeSmallPrint;
		visibility: hidden;

		img {
			max-width: 120px;
			height: auto;
		}
	}

	&__item:hover &__preview {
		visibility: visible;
	}

	&__maplink {
		&--screen-small {
			display: none;
		}

		@include breakpoint($stepsMobileOnly) {
			&--screen-small {
				display: block;
			}

			&--screen-big {
				display: none;
			}
		}
	}

	.js &__save-button {
		display: none;
	}
}
