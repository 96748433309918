@use "sass:math";

$debug: false;

/***************************
 * Layout                  *
 ***************************/
$pageMaxWidth: 960px;
$mainNavMaxWidth: $pageMaxWidth + 2 * 137px;

/***************************
 * Breakpoints             *
 ***************************/
$step1: 900px;
$steps1up: $step1;
$stepsMobileOnly: 0px ($step1 - 1px);

/***************************
 * Magic Sizes             *
 ***************************/
$fhbBannerHeight: 83px;
$fhbBannerPadding: 20px 7px 20px 7px;
$fhbBannerInnerHeight: $fhbBannerHeight - nth($fhbBannerPadding, 1) - nth($fhbBannerPadding, 3);
$mainNavHeight: 50px;
$listInMapWidth: 300px;

$hamburgerWidth: 25px;
$hamburgerHeight: 2px;
$hamburgerSpacing: 5px;

/***************************
 * Colors                  *
 ***************************/
$pageBackgroundColor: white;
$primaryColor: #222; // default color
$invertedPrimaryColor: white;
$secondaryColor: #636567; // eg. meta navigation border margin col
$tertiaryColor: #434345; // TEXT GRAU: Inhaltstexte, Überschriften bis auf H2 & H4
$breadCrumbLinkColor: #085488;

$backgroundHighlightColor: #f2f2f2;

$linkColor: #085488; //  MARITIMES BLAU: Links

$themeColorA: #434345; // Überschriften vom Typ H2 & H4, Formularbuttons, Zierelemente (Seitenleiste)
$themeColorB: #ab2600; // Fokussierung für Tastaturbedienung, Inhaltsmarkierungen, Inhaltsindikatoren
$themeColorC: #009868; // VMZ GRÜN: …
$themeColorD: #D80029; // VMZ ROT: …

$bannerColorBg: #fff;
$bannerColorText: #000;
$mainNavColorFg: #fff;
$mainNavColorBg: #ab2600;
$mainNavColorBgFocus: #f6f8fa;

// $bannerColorBg: #ab2600;

$tableColorA: #085488;
$tableColorB: #464646;
$tableColorC: #777777;
$tableLineColor: #ccc;

/***************************
 * Typography              *
 ***************************/
$lineHeightBase: math.div(21px, 14px);

$fontFamilyDefault: "PT Sans", Helvetica, Arial, sans-serif;
$fontFamilyCaption: "PTSansCaption", "PT Sans Caption", "PT Sans", Helvetica, Arial, sans-serif;

// root font sizes
$fontSizeRoot: 14px;
$fontSizeRootSteps0-0: 0.9 * $fontSizeRoot;

// base font sizes
$fontSizeBase: math.div(15px, $fontSizeRoot) * 1rem; // eg. copy text, table content etc.
$fontSizeMargin: math.div(14px, $fontSizeRoot) * 1rem; // eg. margin col copy text etc.
$fontSizeMinor: math.div(14px, $fontSizeRoot) * 1rem; // eg. Footer, Input, Teaser-Topic
$fontSizeMeta: math.div(13px, $fontSizeRoot) * 1rem; // eg. Abstract, Metadaten
$fontSizeSmallPrint: math.div(12px, $fontSizeRoot) * 1rem; // eg. Meta-Navigation, Bildunterschrift, Icon-Beschriftung

// headlines
$fontSizeHeadline1: math.div(22px, $fontSizeRoot) * 1rem;
$fontSizeHeadline2: math.div(16px, $fontSizeRoot) * 1rem;
$fontSizeHeadline3: math.div(13px, $fontSizeRoot) * 1rem;

// special font sizes
$fontSizeNavigation: $fontSizeBase;

$marginContentHeader: -15px;

//$focus-outline: 5px dotted black;
//$focus-outline: 3px ridge #636567; // #636567 aus dem Logo vmz auf der ehemaligen Website
$focus-outline: 4px solid black;

/***************************
 * TYPO3 fluid content     *
 ***************************/

$stylesContentTextmediaBorderWidth: 1px;
$stylesContentTextmediaBorderColor: #636567;
$stylesContentTextmediaTextMargin: 1em;
$stylesContentTextmediaColumnSpacing: 0.5em;
$stylesContentTextmediaRowSpacing: 1em;
$stylesContentTextmediaBorderPadding: 0;
