@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

@media print {

	.vmzhb-page.vmzhb-container__inner {
		--footer-height: 0 !important;
	}

	.ms3-standalone-main-panel-container,
	.vmzhb-footer {
		padding: 0 !important;
		margin-top: 0 !important;
	}

	.ms3-list,
	.vmzhb-scroll-to-top-button,
	.vmzhb-main-content,
	#vmzhb-main-content,
	.vmzhb-breadcrumb{
		display: none !important;
	}

	header.ms3-feature-selection-info__header,
	.ms3-sticky-header__container--stuck,
	.ms3-panel--docked-left {
		background: #fff !important;
		border-bottom: 0 !important;
	}

	.ms3-map-target,
	.ol-viewport {
		border: 1px solid $secondaryColor !important;
		width: 500px !important;
		height: 500px !important;
	}

	.ms3-feature-selection-info__header,
	.ms3-wrapper--desktop .ms3-feature-selection-info__content,
	.ms3-wrapper--mapOnly .ms3-feature-selection-info__content {
		padding-left: 0 !important;
	}

	.ms3-wrapper--desktop .ms3-feature-selection-info,
	.ms3-wrapper--fullscreen .ms3-feature-selection-info {
		margin: 0 !important;
	}

	.vmzhb-page {
		margin-top: 10px !important;
	}

	abbr[title] {
		border-bottom: none!important;
	}

}



