@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/focus-outline";

.vmzhb-logo {
	@include focusOutline;
	display: inline-block;
	vertical-align: top;
	width: auto;
	height: $fhbBannerInnerHeight;
	background: white;
	border: 4px solid white;
	border-left-width: 5px;
	border-radius: 4px;
	transition: margin 200ms linear;
}
