.vmzhb-base-nav {
	ul, ol {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	em {
		font-style: normal;
	}
}

@mixin navBase {
	@extend .vmzhb-base-nav;
}
