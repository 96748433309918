@import "../../variables";

.vmzhb-rz-wizard {
	margin: 5px -4px;
	border: {
		style: solid;
		color: #ccc;
		width: 1px;
		radius: 4px;
	}

	background: white;
	font-size: $fontSizeMeta;

	input[type="text"] {
		font-size: $fontSizeHeadline2 !important; // prevent iOS zoom
	}
}
