.vmzhb-rz-wizard-offset {
	color: #555;

	&--border {
		border-top: 1px solid #ccc;
		padding-top: 10px;
	}

	&--right {
		padding-left: 30%;
		text-align: right;
	}
}
