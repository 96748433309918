@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-logo-footer {

	img {
		margin: 0;
		max-width: 100%;
		height: auto;
	}
}
