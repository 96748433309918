@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/nav-base";

.vmzhb-skiplinks {
	@include navBase;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 350;
	font-size: $fontSizeNavigation;
	list-style: none;

	.ms3--map-no-scroll &,
	.vmzhb-site--content-hidden & {
		display: none;
	}

	a {
		display: block;
		margin: 0 0 0 0;
		padding: 10px 20px;
		min-width: 40%;
		background: $tertiaryColor;
		color: $invertedPrimaryColor;
		transition: margin 500ms ease-out, background 500ms linear;

		&:focus {
			background: #222;
			margin-top: 0;
		}
	}
}
