@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-site-heading {
	display: inline-block;
	width: 0;
	padding-top: 1px;
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: width 200ms linear;
	font-size: $fontSizeHeadline2;
	line-height: 1.3;
	font-weight: bold;
	letter-spacing: 1px;
	color: inherit;
	vertical-align: middle;

	@include breakpoint($steps1up) {
		width: 72%;
	}
}
