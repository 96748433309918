@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
	// debug helper:
	//outline: 2px solid darkmagenta;
	//background: pink !important;
}

.js-focus-visible .focus-visible:focus {
	outline-offset: 3px;
	outline: $focus-outline !important; // normalize and ms3 sss set :focus for some elements, we want to override it
	// see also blocks/_panel-boxes for special focus handling of start page boxes
}

a.focus-visible:focus {
	outline-offset: 3px;
}

.js-focus-visible .vmzhb-main-navigation__icon.focus-visible:focus {
	// see also _toggle-content-button.scss which loads svg from file
	background-color: $mainNavColorBgFocus;
	path {
		fill: $themeColorB;
	}
}

// make map focus visible, outline is invisible
.ms3-wrapper--fullscreen .ms3-list,
.ms3-wrapper--fullscreen .ms3-list .ms3-list__item,
.ms3-map-target {
	position: relative;

	&.focus-visible {
		outline: none !important;
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: $focus-outline;
			pointer-events: none;
		}
	}
}
