@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/main-column";

@mixin vmzhb-content-container {
	@include mainColumn;

	@if $debug {
		outline: 1px dashed red;
	}
	padding-bottom: 9%;

	.ce-bodytext ul,
	.ce-bodytext ol {
		padding-left: 1.5em;
	}

	.vmzhb-layout-wide & {
		@include breakpoint($steps1up) {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			padding: 0 8.2% 9%;
		}
	}
}
