@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-footer-navigation {
	background-color: #3b4d58;
	padding: {
		top: 10px;
		bottom: 20px;
	}

	@media print {
		display: none;
	}

	> ul {
		max-width: $pageMaxWidth;
		margin: {
			left: auto;
			right: auto;
		}
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		@include breakpoint($steps1up) {
			flex-direction: row;
		}

		@include breakpoint($stepsMobileOnly) {
			flex-direction: column;
		}
	}

	&__item {
		display: inline-block;
		line-height: 1.2;

		& + & {
			@include breakpoint($steps1up) {
				border-left: {
					style: solid;
					width: 1px;
					color: #fff;
				}
				padding-left: 5px;
				margin-left: 5px;
			}
		}

		a {
			display: inline-block;
			color: #fff;
			font-size: $fontSizeBase;
			vertical-align: middle;

			&:hover,
			&:focus, {
				text-decoration: underline;
			}

			@include breakpoint($stepsMobileOnly) {
				padding: 15px;
			}
		}
	}
}
