@use "sass:math";

@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/main-column";

$lineHeight: 1.2;
$numberOfItems: 6;

.vmzhb-main-navigation {
	line-height: $lineHeight;
	font-size: $fontSizeNavigation;
	color: $mainNavColorFg;
	background-color: $mainNavColorBg;
	width: 100%;
	position: relative;

	.ms3--map-no-scroll & {
		display: none;
	}

	@include breakpoint($stepsMobileOnly) {
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		// this does not currently work for iOS, but fixes the weird scrolling behaviour when the navigation is scrolled all the
		// way to the top/bottom not capturing the scrolling
		overscroll-behavior-y: contain;

		// add some overscroll padding to the bottom as a visual cue that the end is reached and to prevent weird chroma
		// overlapping the menu on the bottom of the viewport
		padding-bottom: 70px;
	}

	@include breakpoint($steps1up) {
		box-shadow: 1px 0.5rem 0.625rem #0006 inset;
		display: flex;
	}

	@include breakpoint($mainNavMaxWidth) {
		padding: {
			left: calc(50% - #{math.div($mainNavMaxWidth, 2)});
			right: calc(50% - #{math.div($mainNavMaxWidth, 2)});
		}
	}

	> ul {
		list-style: none;
		margin: 0;
		padding: 0;
		box-sizing: border-box;

		@include breakpoint(0 $mainNavMaxWidth - 1) {
			flex-grow: 1;
			flex-shrink: 1;
		}

		@include breakpoint($mainNavMaxWidth) {
			width: $pageMaxWidth;
			flex-grow: 0;
			flex-shrink: 0;
		}

		@include breakpoint($steps1up) {
			display: flex;
			border-left: 1px solid #fff;
			border-right: 1px solid #fff;
			position: relative;
		}
	}

	&__item {
		@include breakpoint($steps1up) {
			flex-grow: 1;
			flex-shrink: 1;
			border-right: 1px solid #fff;

			&:last-child {
				border-right: 0;
			}
		}

		> a,
		> span {
			display: inline-flex;
			align-items: center;
			margin: 0;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: inherit;
			transition: background-color ease-out 100ms;
			width: 100%;
			hyphens: auto;

			@include breakpoint($steps1up) {
				justify-content: center;
				text-align: center;
				min-height: $mainNavHeight;
				line-height: 1.2;
				padding: 15px 0 10px 0;
				font-family: $fontFamilyCaption;
				font-size: $fontSizeMinor;
				font-weight: 400;
				letter-spacing: 1px;
			}

			@include breakpoint($stepsMobileOnly) {
				font-weight: bold;
				padding: {
					left: 5px;
					right: 5px;
					top: 10px;
					bottom: 10px;
				}
				border-bottom: {
					style: solid;
					width: 1px;
					color: #fff;
				}
			}
		}

//		> a[data-mapsight-preset] {
//			@include ripple-click($themeColorA);
//		}

		&--current {
			> a,
			> span {
				@include breakpoint($steps1up) {
					background-color: $mainNavColorBgFocus;
					color: #000;
				}
			}
		}

		& > a:hover,
		& > a:focus {
			@include breakpoint($stepsMobileOnly) {
				background-color: $mainNavColorBgFocus;
				color: $mainNavColorBg;
			}
		}

		.no-js & > a:hover,
		& > a:focus,
		&--active > a {
			@include breakpoint($steps1up) {
				background-color: $mainNavColorBgFocus;
				color: $mainNavColorBg;
			}
		}

		> span {
			cursor: default;
		}

		// &--ajax-loaded {
		// 	background: $themeColorA !important;

		// 	> a,
		// 	> span {
		// 		background: $themeColorA !important;;
		// 		color: $invertedPrimaryColor !important;;
		// 	}
		// }

		// .vmzhb--page-loading & {
		// 	> a:hover,
		// 	> span:hover {
		// 		background: $themeColorA !important;;
		// 		color: $invertedPrimaryColor !important;;
		// 	}
		// }
	}

	// &__sub {
	// 	> ul {
	// 		margin-left: 5px;
	// 		background-color: $backgroundHighlightColor;
	// 		color: $primaryColor;
	// 		border-top: 1px solid $themeColorA;
	// 	}
	// }

	&__sub {
		width: 100%;
		background-color: $bannerColorBg;
		z-index: 2;

		// on mobile only show current sub menu
		@include breakpoint($stepsMobileOnly) {
			.vmzhb-main-navigation__item:not(.vmzhb-main-navigation__item--current) & {
				display: none;
			}
		}

		@include breakpoint($steps1up) {
			position: absolute;
			width: auto;
			left: -1px;
			right: -1px;
			top: 100%;
			display: none;
			border-bottom: {
				color: $mainNavColorBg;
				style: solid;
				width: 5px;
				left-radius: 5px;
				right-radius: 5px;
			}
			padding: 10px;

			.vmzhb-main-navigation__item--active > &,
			.no-js .vmzhb-main-navigation__item:hover & {
				display: block;
			}
		}

		> ul {
			margin: 0;
			padding: 0;
			width: 100%;

			@include breakpoint($steps1up) {
				list-style: {
					type: circle;
					position: inside;
				}
				column: {
					count: 3;
					rule: {
						color: #000;
						style: dotted;
						width: 1px;
					}
				}
			}
		}
	}

	&__sub-item {
		@include breakpoint($steps1up) {
			display: block;

			&:first-child {
				border-left: 0;
			}
		}

		> a,
		> span {
			display: inline-block;
			border: 0;
			width: 100%;
			height: 100%;

			@include breakpoint($steps1up) {
				color: #000;
				padding: {
					left: 15px;
					right: 15px;
					top: 12px;
					bottom: 12px;
				}
				font-weight: bold;
			}

			@include breakpoint($stepsMobileOnly) {
				background-color: #444;
				color: #fff;
				padding: {
					left: 10px;
					right: 10px;
					top: 10px;
					bottom: 10px;
				}
				border-bottom: {
					style: solid;
					width: 1px;
					color: #fff;
				}

				&:hover,
				&:focus {
					background-color: #fff;
					color: #444;
				}
			}
		}

		> a:hover,
		> a:focus {
			text-decoration: underline;
		}

		> span > em {
			font-style: normal;
		}

		&--current {
			font-weight: bold;

			span {
				text-decoration: underline!important;
			}
		}
	}

	&__left,
	&__right {
		display: flex;
		align-items: center;
		justify-content: center;
		width: #{math.div($mainNavMaxWidth - $pageMaxWidth, 2)};
		min-height: $mainNavHeight;
		flex-grow: 0;
		flex-shrink: 0;
		padding: {
			left: 5px;
			right: 5px;
		}

		@include breakpoint($stepsMobileOnly) {
			display: none;
		}
	}

	&__logo {
		width: math.div(137, 50) * $mainNavHeight;
		height: $mainNavHeight;
		position: relative;
		display: inline-block;
		pointer-events: none;

		.vmzhb-site--scrolled & {
			pointer-events: auto;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 5px;
			background: {
				size: contain;
				origin: content-box;
				position: center;
				repeat: no-repeat;
			}
			opacity: 0.0;
			transition: {
				property: opacity;
				duration: 200ms;
				timing-function: ease-in-out;
			}
		}

		&::before {
			background: {
				image: url(../img/fhb_white.svg);
				color: transparent;
			}

			.vmzhb-site--scrolled & {
				opacity: 1.0;
			}
		}

		&::after {
			z-index: 1;
			background: {
				image: url(../img/fhb_red.svg);
				color: #fff;
			}
		}

		&:hover,
		&:focus {
			&::before {
				opacity: 0.0;
			}

			&::after {
				opacity: 1.0;
			}
		}
	}

	&__icon {
		border: {
			style: solid;
			width: 2px;
			color: transparent;
		}

		&:hover,
		&:focus {
			border-color: #fff;
		}

		&--gebaerden-sprache {
			padding: {
				left: 4px;
			}
		}

		&--leichte-sprache {
			padding: {
				left: 4px;
				right: 4px;
			}
		}
	}

	@include breakpoint($stepsMobileOnly) {
		.js & {
			display: none;
		}

		.js &--active {
			display: block;
		}
	}

	@media print {
		display: none !important;
	}
}
