/* Accessibility: hide screen reader texts (and prefer "top" for RTL languages).
Reference: http://blog.rrwd.nl/2015/04/04/the-screen-reader-text-class-why-and-how/ */
.mejs__offscreen {
	border: 0;
	clip: rect( 1px, 1px, 1px, 1px );
	-webkit-clip-path: inset( 50% );
	clip-path: inset( 50% );
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal;
}

.mejs__container {
	background: #000;
	box-sizing: border-box;
	font-family: 'Helvetica', Arial, serif;
	position: relative;
	text-align: left;
	text-indent: 0;
	vertical-align: top;
}

.mejs__container * {
	box-sizing: border-box;
}

/* Hide native play button and control bar from iOS to favor plugin button */
.mejs__container video::-webkit-media-controls,
.mejs__container video::-webkit-media-controls-panel,
.mejs__container video::-webkit-media-controls-panel-container,
.mejs__container video::-webkit-media-controls-start-playback-button {
	-webkit-appearance: none;
	display: none !important;
}

.mejs__fill-container,
.mejs__fill-container .mejs__container {
	height: 100%;
	width: 100%;
}

.mejs__fill-container {
	background: transparent;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

.mejs__container:focus {
	outline: none;
}

.mejs__iframe-overlay {
	height: 100%;
	position: absolute;
	width: 100%;
}

.mejs__embed,
.mejs__embed body {
	background: #000;
	height: 100%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

.mejs__fullscreen {
	overflow: hidden !important;
}

.mejs__container-fullscreen {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
}

.mejs__container-fullscreen .mejs__mediaelement,
.mejs__container-fullscreen video {
	height: 100% !important;
	width: 100% !important;
}

/* Start: LAYERS */
.mejs__background {
	left: 0;
	position: absolute;
	top: 0;
}

.mejs__mediaelement {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.mejs__poster {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;
}

:root .mejs__poster-img {
	display: none;
}

.mejs__poster-img {
	border: 0;
	padding: 0;
}

.mejs__overlay {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
}

.mejs__layer {
	z-index: 1;
}

.mejs__overlay-play {
	cursor: pointer;
}

.mejs__overlay-button {
	background: url('../img/mediaplayer/mejs-controls.svg') no-repeat;
	background-position: 0 -39px;
	height: 80px;
	width: 80px;
}

.mejs__overlay:hover > .mejs__overlay-button {
	background-position: -80px -39px;
}

.mejs__overlay-loading {
	height: 80px;
	width: 80px;
}

.mejs__overlay-loading-bg-img {
	-webkit-animation: mejs__loading-spinner 1s linear infinite;
	animation: mejs__loading-spinner 1s linear infinite;
	background: transparent url('../img/mediaplayer/mejs-controls.svg') -160px -40px no-repeat;
	display: block;
	height: 80px;
	width: 80px;
	z-index: 1;
}

@-webkit-keyframes mejs__loading-spinner {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes mejs__loading-spinner {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* End: LAYERS */

/* Start: CONTROL BAR */
.mejs__controls {
	bottom: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 40px;
	left: 0;
	list-style-type: none;
	margin: 0;
	padding: 0 10px;
	position: absolute;
	width: 100%;
	z-index: 3;
}

.mejs__controls:not([style*='display: none']) {
	background: rgba(255, 0, 0, 0.7);
	background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.35));
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.35));
}

.mejs__button,
.mejs__time,
.mejs__time-rail {
	font-size: 10px;
	height: 40px;
	line-height: 10px;
	margin: 0;
	width: 32px;
}

.mejs__button > button {
	background: transparent url('../img/mediaplayer/mejs-controls.svg');
	border: 0;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 20px;
	line-height: 0;
	margin: 10px 6px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	text-decoration: none;
	width: 20px;
}

/* :focus for accessibility */
.mejs__button > button:focus {
	outline: dotted 1px #999;
}

.mejs__container-keyboard-inactive a,
.mejs__container-keyboard-inactive a:focus,
.mejs__container-keyboard-inactive button,
.mejs__container-keyboard-inactive button:focus,
.mejs__container-keyboard-inactive [role=slider],
.mejs__container-keyboard-inactive [role=slider]:focus {
	outline: 0;
}

/* End: CONTROL BAR */

/* Start: Time (Current / Duration) */
.mejs__time {
	box-sizing: content-box;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	height: 24px;
	overflow: hidden;
	padding: 16px 6px 0;
	text-align: center;
	width: auto;
}

/* End: Time (Current / Duration) */

/* Start: Play/Pause/Stop */
.mejs__play > button {
	background-position: 0 0;
}

.mejs__pause > button {
	background-position: -20px 0;
}

.mejs__replay > button {
	background-position: -160px 0;
}

/* End: Play/Pause/Stop */

/* Start: Progress Bar */
.mejs__time-rail {
	direction: ltr;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	height: 40px;
	margin: 0 10px;
	padding-top: 10px;
	position: relative;
}

.mejs__time-total,
.mejs__time-buffering,
.mejs__time-loaded,
.mejs__time-current,
.mejs__time-float,
.mejs__time-hovered,
.mejs__time-float-current,
.mejs__time-float-corner,
.mejs__time-marker {
	border-radius: 2px;
	cursor: pointer;
	display: block;
	height: 10px;
	position: absolute;
}

.mejs__time-total {
	background: rgba(255, 255, 255, 0.3);
	margin: 5px 0 0;
	width: 100%;
}

.mejs__time-buffering {
	-webkit-animation: buffering-stripes 2s linear infinite;
	animation: buffering-stripes 2s linear infinite;
	background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
	background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
	background-size: 15px 15px;
	width: 100%;
}

@-webkit-keyframes buffering-stripes {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 30px 0;
	}
}

@keyframes buffering-stripes {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 30px 0;
	}
}

.mejs__time-loaded {
	background: rgba(255, 255, 255, 0.3);
}

.mejs__time-current,
.mejs__time-handle-content {
	background: rgba(255, 255, 255, 0.9);
}

.mejs__time-hovered {
	background: rgba(255, 255, 255, 0.5);
	z-index: 10;
}

.mejs__time-hovered.negative {
	background: rgba(0, 0, 0, 0.2);
}

.mejs__time-current,
.mejs__time-buffering,
.mejs__time-loaded,
.mejs__time-hovered {
	left: 0;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transition: 0.15s ease-in all;
	transition: 0.15s ease-in all;
	width: 100%;
}

.mejs__time-buffering {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

.mejs__time-hovered {
	-webkit-transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
	transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
}

.mejs__time-hovered.no-hover {
	-webkit-transform: scaleX(0) !important;
	-ms-transform: scaleX(0) !important;
	transform: scaleX(0) !important;
}

.mejs__time-handle,
.mejs__time-handle-content {
	border: 4px solid transparent;
	cursor: pointer;
	left: 0;
	position: absolute;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	z-index: 11;
}

.mejs__time-handle-content {
	border: 4px solid rgba(255, 255, 255, 0.9);
	border-radius: 50%;
	height: 10px;
	left: -7px;
	top: -4px;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	width: 10px;
}

.mejs__time-rail:hover .mejs__time-handle-content,
.mejs__time-rail .mejs__time-handle-content:focus,
.mejs__time-rail .mejs__time-handle-content:active {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.mejs__time-float {
	background: #eee;
	border: solid 1px #333;
	bottom: 100%;
	color: #111;
	display: none;
	height: 17px;
	margin-bottom: 9px;
	position: absolute;
	text-align: center;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 36px;
}

.mejs__time-float-current {
	display: block;
	left: 0;
	margin: 2px;
	text-align: center;
	width: 30px;
}

.mejs__time-float-corner {
	border: solid 5px #eee;
	border-color: #eee transparent transparent;
	border-radius: 0;
	display: block;
	height: 0;
	left: 50%;
	line-height: 0;
	position: absolute;
	top: 100%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 0;
}

.mejs__long-video .mejs__time-float {
	margin-left: -23px;
	width: 64px;
}

.mejs__long-video .mejs__time-float-current {
	width: 60px;
}

.mejs__broadcast {
	color: #fff;
	height: 10px;
	position: absolute;
	top: 15px;
	width: 100%;
}

/* End: Progress Bar */

/* Start: Fullscreen */
.mejs__fullscreen-button > button {
	background-position: -80px 0;
}

.mejs__unfullscreen > button {
	background-position: -100px 0;
}

/* End: Fullscreen */

/* Start: Mute/Volume */
.mejs__mute > button {
	background-position: -60px 0;
}

.mejs__unmute > button {
	background-position: -40px 0;
}

.mejs__volume-button {
	position: relative;
}

.mejs__volume-button > .mejs__volume-slider {
	-webkit-backface-visibility: hidden;
	background: rgba(50, 50, 50, 0.7);
	border-radius: 0;
	bottom: 100%;
	display: none;
	height: 115px;
	left: 50%;
	margin: 0;
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 25px;
	z-index: 1;
}

.mejs__volume-button:hover {
	border-radius: 0 0 4px 4px;
}

.mejs__volume-total {
	background: rgba(255, 255, 255, 0.5);
	height: 100px;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 8px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 2px;
}

.mejs__volume-current {
	background: rgba(255, 255, 255, 0.9);
	left: 0;
	margin: 0;
	position: absolute;
	width: 100%;
}

.mejs__volume-handle {
	background: rgba(255, 255, 255, 0.9);
	border-radius: 1px;
	cursor: ns-resize;
	height: 6px;
	left: 50%;
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 16px;
}

.mejs__horizontal-volume-slider {
	display: block;
	height: 36px;
	position: relative;
	vertical-align: middle;
	width: 56px;
}

.mejs__horizontal-volume-total {
	background: rgba(50, 50, 50, 0.8);
	border-radius: 2px;
	font-size: 1px;
	height: 8px;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 16px;
	width: 50px;
}

.mejs__horizontal-volume-current {
	background: rgba(255, 255, 255, 0.8);
	border-radius: 2px;
	font-size: 1px;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.mejs__horizontal-volume-handle {
	display: none;
}

/* End: Mute/Volume */

/* Start: Track (Captions and Chapters) */
.mejs__captions-button,
.mejs__chapters-button {
	position: relative;
}

.mejs__captions-button > button {
	background-position: -140px 0;
}

.mejs__chapters-button > button {
	background-position: -180px 0;
}

.mejs__captions-button > .mejs__captions-selector,
.mejs__chapters-button > .mejs__chapters-selector {
	background: rgba(50, 50, 50, 0.7);
	border: solid 1px transparent;
	border-radius: 0;
	bottom: 100%;
	margin-right: -43px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 50%;
	visibility: visible;
	width: 86px;
}

.mejs__chapters-button > .mejs__chapters-selector {
	margin-right: -55px;
	width: 110px;
}

.mejs__captions-selector-list,
.mejs__chapters-selector-list {
	list-style-type: none !important;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.mejs__captions-selector-list-item,
.mejs__chapters-selector-list-item {
	color: #fff;
	cursor: pointer;
	display: block;
	list-style-type: none !important;
	margin: 0 0 6px;
	overflow: hidden;
	padding: 0;
}

.mejs__captions-selector-list-item:hover,
.mejs__chapters-selector-list-item:hover {
	background-color: rgb(200, 200, 200) !important;
	background-color: rgba(255, 255, 255, 0.4) !important;
}

.mejs__captions-selector-input,
.mejs__chapters-selector-input {
	clear: both;
	float: left;
	left: -1000px;
	margin: 3px 3px 0 5px;
	position: absolute;
}

.mejs__captions-selector-label,
.mejs__chapters-selector-label {
	cursor: pointer;
	float: left;
	font-size: 10px;
	line-height: 15px;
	padding: 4px 10px 0;
	width: 100%;
}

.mejs__captions-selected,
.mejs__chapters-selected {
	color: rgba(33, 248, 248, 1);
}

.mejs__captions-translations {
	font-size: 10px;
	margin: 0 0 5px;
}

.mejs__captions-layer {
	bottom: 0;
	color: #fff;
	font-size: 16px;
	left: 0;
	line-height: 20px;
	position: absolute;
	text-align: center;
}

.mejs__captions-layer a {
	color: #fff;
	text-decoration: underline;
}

.mejs__captions-layer[lang=ar] {
	font-size: 20px;
	font-weight: normal;
}

.mejs__captions-position {
	bottom: 15px;
	left: 0;
	position: absolute;
	width: 100%;
}

.mejs__captions-position-hover {
	bottom: 35px;
}

.mejs__captions-text,
.mejs__captions-text * {
	background: rgba(20, 20, 20, 0.5);
	box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
	padding: 0;
	white-space: pre-wrap;
}

.mejs__container.mejs__hide-cues video::-webkit-media-text-track-container {
	display: none;
}

/* End: Track (Captions and Chapters) */

/* Start: Error */
.mejs__overlay-error {
	position: relative;
}
.mejs__overlay-error > img {
	left: 0;
	max-width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
}
.mejs__cannotplay,
.mejs__cannotplay a {
	color: #fff;
	font-size: 0.8em;
}

.mejs__cannotplay {
	position: relative;
}

.mejs__cannotplay p,
.mejs__cannotplay a {
	display: inline-block;
	padding: 0 15px;
	width: 100%;
}
/* End: Error */
