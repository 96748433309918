@import "breakpoint-sass/stylesheets/breakpoint";
@import "@neonaut/lib-scss/mixins/visually-hidden";

@import "../variables";

.vmzhb-toggle-content-button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: {
		style: solid;
		width: 1px;
		color: $mainNavColorBg;
	}
	vertical-align: middle;
	border: {
		style: solid;
		width: 2px;
		color: transparent;
	}

	&:hover,
	&:focus {
		border-color: #fff;
	}
	.js-focus-visible &:focus {
		border-color: transparent;
	}
	.js-focus-visible &.focus-visible:focus {
		border-color: $mainNavColorBgFocus;
		background-color: $mainNavColorBgFocus;
	}

	&:after {
		content: '';
		display: block;
		width: 21px;
		height: 21px;
		border: 1px solid white;
		background: transparent url(../img/icon-toggle-content.svg) no-repeat center;
		background-size: 16px 16px;
	}
	.js-focus-visible &.focus-visible:focus:after {
		border-color: $mainNavColorBg;
		background-image: url(../img/icon-toggle-content_focus.svg);
	}

	i {
		@include visually-hidden;
	}
}
