@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-notifications {
	background-color: $themeColorC;
	color: $pageBackgroundColor;

	.ms3--map-no-scroll & {
		display: none;
	}

	&__headline {
		margin: 0;
		padding: 10px;
		border: 0;

		font-size: $fontSizeHeadline2;
		line-height: 1.5;

		color: inherit;
		text-transform: uppercase;


		@include breakpoint($steps1up) {
			float: left;
			width: 180px;
		}
	}

	&__items {
		display: block;
		padding: 6px 56px 6px 32px;
		margin: 0;
		border: solid $themeColorC;
		border-width: 4px 0;

		list-style-type: disc;
		background: $backgroundHighlightColor;

		@include breakpoint($steps1up) {
			margin-left: 180px;
		}
	}

	&__item {
		font-size: $fontSizeHeadline2;
		font-weight: bold;

		color: black;

		a {
			color: inherit;

			&:hover,
			&:focus {
				color: $themeColorA;
				text-decoration: underline;
			}
		}
	}

	&__placeholder {
		display: block;
	}
}
