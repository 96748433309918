.vmzhb-rz-wizard-steps {
	display: flex;
	margin: 0;
	padding: 0;

	flex-direction: row;
	list-style-type: decimal;
	list-style-position: inside;

	border-bottom: 1px solid #ccc;
	background: whitesmoke;
}

.vmzhb-rz-wizard-step {
	position: relative;

	height: 32px;
	padding: 5px 10px;
	flex-basis: 50%;
	flex-grow: 1;

	overflow: hidden;

	&--active {
		background: white;
		font-weight: bold;
	}

	&--clickable {
		cursor: pointer;
	}

	&:not(:first-child) {
		flex-basis: calc(50% + 30px);
		padding-left: 30px;

		&::before {
			content: "";

			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;

			display: block;
			width: 34px;
			height: 34px;
			border: 1px solid #ccc;

			background: whitesmoke;
			transform: rotate(45deg) translateX(-16px) translateY(16px);
			transform-origin: 50% 50%;
		}
	}

	&--inactive:not(:first-child)::before {
		background: white;
	}
}
