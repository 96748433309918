@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-marginal {
	font-size: $fontSizeMargin;
	line-height: 2;
	margin-top: 100px;
	hyphens: auto;

	@media print {
		display: none;
	}

	.ms3--map-no-scroll &,
	.vmzhb-site--content-hidden & {
		display: none;
	}

	img {
		min-width: auto;
	}

	h3 {
		color: $tertiaryColor;
		font-weight: 700;
		border: 0;
		padding: 0;
		margin: 0;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	.ce-textpic {
		overflow: hidden;
		padding-top: 0;
	}

	.ce-menu {
		margin: 0;

		&__item {
			border-bottom: 1px solid $tableLineColor;
			list-style: none;
			padding: 0;

			&:hover,
			&:focus {
				background-color: $backgroundHighlightColor;
			}

			> a {
				display: inline-block;
				width: 100%;
				padding: 1em 6px;
				font-weight: 400;
				color: $primaryColor;

				img {
					margin-right: 8px;
					float: left;
				}
			}

			&--current > a {
				color: $invertedPrimaryColor;
				background-color: $themeColorA;
			}

			> a:hover,
			> a:focus {
				color: $themeColorB;
				background-color: $backgroundHighlightColor;
			}
		}
	}

	.news {
		padding-bottom: 30px;
	}

	@include breakpoint($stepsMobileOnly) {
		clear: both;
		margin: 50px 2.088% 0;
	}

	@include breakpoint($steps1up) {
		width: 25%;
		margin-left: 75%;
	}

	@if $debug {
		outline: 1px dashed yellow;
	}
}
