@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/main-column";

.vmzhb-breadcrumb {
	@include mainColumn;
	padding: 0;
	margin-top: 4.6%;
	margin-bottom: 1%;
	list-style: none;
	font-size: $fontSizeSmallPrint;

	ul {
		padding: 0;
		margin: 0;
	}

	&-item {
		display: inline-block;

		// right arrow
		&::before {
			content: '';
			display: inline-block;
			border: {
				color: transparent;
				style: solid;
				width: 0.25rem 0 0.25rem 0.43125rem;
				left-color: $themeColorA;
			}
			margin-right: 0.25rem;
		}

		> a,
		> span {
			padding-right: 8px;
			padding-left: 3px;
			text-decoration: none;
		}

		> a {
			color: $breadCrumbLinkColor;
		}

		> a:hover,
		> a:focus {
			color: $themeColorB;
		}

		&--first {
			> a {
				padding-left: 0;
			}

			&:before {
				display: none;
			}
		}

		&--last {
			color: $tertiaryColor;
		}
	}
}
