@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

@mixin mainColumn {
	margin-left: 2.088%;
	margin-right: 2.088%;
	transition: margin 200ms linear;

	@include breakpoint($steps1up) {
		float: left;
		width: 62.5%;
		margin-left: 8.2%;
		margin-right: 0;
	}
}
