@font-face {
	font-family: 'PT Sans';
	src: local("PT Sans Regular"), local("PT Sans"), url(../fonts/PTS55F-webfont.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: local("PT Sans Bold"), local("PT Sans"), url(../fonts/PTS75F-webfont.woff) format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "PTSansCaption";
	src: local("PT Sans Caption"), url(../fonts/PTC55F-webfont.woff) format("woff");
	font-style: normal;
	font-weight: normal;
}
