// import generated sprite map
@import "svg-sprites/svg-sprite-sprite";

/**
 * sprite($label, all): icon and base
 * sprite($label, bg): background position only
 * sprite($label, size): width and height only
 */
@mixin sprite($label, $type: all) {
	$icon: map-get($sprite-map, $label);

	@if $icon {
		@if $type == all or $type == bg {
			background-position: round(map-get($icon, x)) round(map-get($icon, y));
		}

		@if $type == all or $type == size {
			width: round(map-get($icon, width));
			height: round(map-get($icon, height));
		}
	}
}

@function sprite($label, $type) {
	$icon: map-get($sprite-map, $label);

	@if $icon {
		@return map-get($icon, $type);
	}
}

.vmzhb-sprite,
.vmzhb-icon:before {
	$sprite: map-get($sprite-map, sprite);
	background-image: url("../img/svg-sprite-sprite.svg?v=2");
	background-size: round(map-get($sprite, width)) round(map-get($sprite, height));

	// fallback
	.no-js &,
	.no-backgroundsize & {
		background-image: url("../img/svg-sprite-sprite.png?v=2");
	}
}

.vmzhb-icon {
	display: inline-block;
	text-align: center;

	&:before {
		display: inline-block;
		position: relative;
		top: -2px;
		content: "";
		vertical-align: middle;
	}
}

@each $label, $value in $sprite-map {
	@if $label != 'sprite' {
		.vmzhb-icon--#{'' + $label}:before {
			@include sprite($label);
		}
	}
}
