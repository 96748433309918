@import "flatpickr/dist/themes/material_blue.css";
@import "flatpickr/dist/plugins/monthSelect/style.css";


.flatpickr-months .flatpickr-month {
	height: 40px;
}

.flatpickr-months,
.flatpickr-monthSelect-months {
	user-select: none;
}
