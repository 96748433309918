@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

.vmzhb-fhb-logo {
	float: left;
	height: $fhbBannerInnerHeight;
	width: 105px;
	margin: 0 0 0 9px;
	transition: margin 200ms linear;

	@include breakpoint($stepsMobileOnly) {
		margin-left: 12px;
	}
}
