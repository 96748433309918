// modules
@import "variables";

@import "normalize-css/normalize.css";

$pswp__assets-path: '../img/photoswipe/';
@import "photoswipe/src/css/main";
@import "photoswipe/src/css/default-skin/default-skin";

@import "@neonaut/lib-scss/global/box-sizing";

// sprite
@import "sprite";

// global
@import "global/base";
@import "global/fonts";
@import "global/focus";
@import "global/typography";
@import "global/body";
@import "global/typo3-override";

// blocks
@import "blocks/area";
@import "blocks/ce";
@import "blocks/container";
@import "blocks/content";
@import "blocks/content-container";
@import "blocks/scroll-to-top-button";
@import "blocks/fhb-banner";
@import "blocks/fhb-logo";
@import "blocks/internal";
@import "blocks/link";
@import "blocks/logo";
@import "blocks/main-navigation";
@import "blocks/main-navigation-toggle";
@import "blocks/marginal";
@import "blocks/feature";
@import "blocks/notifications";
@import "blocks/page";
@import "blocks/site-heading";
@import "blocks/skiplinks";
@import "blocks/toggle-content-button";
@import "blocks/overflow-scroll-area";
@import "blocks/breadcrumb";
@import "blocks/footer";
@import "blocks/footer-navigation";
@import "blocks/logo-footer";
@import "blocks/mediaelementplayer";
@import "blocks/vba-a1-a27";
@import "blocks/video";
@import "blocks/webcams";
@import "blocks/chart";
@import "blocks/rz-wizard/index";

// modules
@import "mapsight-ui-integration";
@import "flatpickr";

// print
@import "global/print";

// trumps
@import "@neonaut/lib-scss/global/helpers";

// disables page scrolling while mob-nav is open
// TODO: restore scroll position
.vmzhb--main-navigation-open body,
.vmzhb--main-navigation-open {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

// fix outline for anchor with <img> in it
.js-vmzhb-image-gallery-image-link {
	display: block;
}

/* BITV konform, leicht Sprache in größerer Schrift */
html .vmzhb-style-2  p,
.vmzhb-style-2 .vmzhb-marginal,
.vmzhb-style-2 .vmzhb-marginal h3 {
	font-size: 15.6px!important;
	line-height: 1.5em!important;
}
