.vmzhb-rz-wizard-radio {
	display: inline-block;
	margin: 5px 10px 5px 0;
	padding: 5px;

	&__input {

	}

	&__label {
		padding: 2px 5px;
	}
}
