@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";


/* full width with background */
.vmzhb-area {
	float: left;
	width: 100%;
	clear: both;

	@if $debug {
		outline: 1px dashed grey;
	}

	&--meta {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 300;
		background: $bannerColorBg;
		box-shadow: 0 0 1.25rem #00000080;

		@media print {
			position: initial;
			box-shadow: none;
		}

		@include breakpoint($stepsMobileOnly) {
			max-height: 100%; // cannot use 100vh because android chrome includes chroma in 100vh leading to a
			                  // truncated navigation, see https://dev.to/phyks/comment/4411
			display: flex;
			flex-direction: column;
		}
	}

	&--notifications {
		margin: 0;
		border-bottom: 1px solid black;
		background: $themeColorC;
		color: $pageBackgroundColor;
	}
}
