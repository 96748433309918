@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";

h1, h2, h3, h4, h5, h6, legend, caption {
	font-weight: bold;
	margin: 1em 0 0.3em 0;
	text-align: left;
	transition: font-size 500ms ease-in-out;

	a {
		color: $themeColorA;
		text-decoration: underline;
	}

	// headline after headline closer
	& + h1,
	& + h2,
	& + h3,
	& + h4,
	& + h5,
	& + h6 {
		margin-top: 0;
	}
}

h1 {
	color: $tertiaryColor;
	font-size: $fontSizeHeadline1;
	line-height: 1.318;
	letter-spacing: 1px;
}

h1 + h2,
h1 + h3,
h2 + h3 {
	padding-top: 0;
	border-top: 0;
}

h2 {
	padding-top: 1.4375rem;
	font-size: $fontSizeHeadline2;
	line-height: 1.1875;
}

h3, h5, caption {
	color: $tertiaryColor;
	font-size: $fontSizeHeadline2;
	line-height: 1.1875;
}

h4, legend {
	color: $themeColorA; // TODO: colour contrast not high enough!
	font-size: $fontSizeHeadline3;
	line-height: 1.2308;
}

p, ul, ol, dl {
	margin: 0.3em 0 0.5em 0;
}

