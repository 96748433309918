@import "breakpoint-sass/stylesheets/breakpoint";

@import "../variables";
@import "../mixins/focus-outline";

.vmzhb-link {
	@include focusOutline;
	color: $themeColorA;
	text-decoration: none;
	font-weight: bold;

	&:before {
		content: '&';
		margin-right: 5px;
	}

	&:hover,
	&:focus {
		color: $primaryColor;
	}

	&:focus {
		background: $backgroundHighlightColor;
	}
}

/* ToDo: remove first sector in the future. */
.external-link-new-window,
.link-external-blank {
	background: url("../img/external.svg") no-repeat 100% 50%;
	padding-right: 1.3em;
}

/* ToDo: remove first sector in the future. */
.download,
.link-download {
	background: url("../img/download.svg") no-repeat 100% 50%;
	padding-right: 1.3em;
}

/* ToDo: remove first selector in the future. */
.internal-link,
.link-internal {
	background: url("../img/internal.svg") no-repeat 100% 50%;
	padding-right: 1.3em;
}

.vmzhb-read-more-link {
	color: $themeColorA;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: -6px;
	font-weight: bold;
}

a {
	color: $themeColorA;
	text-decoration: none;
}
